import classnames from 'classnames'
import Helmet from 'react-helmet'
// import '../styles/globals.css'
import Header from './Header'
import Footer from './Footer'
import Toast from './toast'
import { useEffect } from 'react'
import { useMoralis } from '../hooks/moralis'
const Layout: React.FC<{ className?: string }> = ({ className, children }) => {
  const { Moralis } = useMoralis()
  return (
    <div className={classnames('font-regularText', className)}>
      <Helmet>
        <title>CTIO</title>
        {/* <link rel="icon" href="../images/gatsby-icon.png" /> */}
        {/* mobile adaptation */}
        <meta name="page-view-size" content="1920*1080" />
        <meta
          content="width=device-width, height=device-height,initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          name="viewport"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="format-detection" content="telephone=no, email=no" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="format-detection" content="telphone=no, email=no" />
        <meta name="renderer" content="webkit" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="MobileOptimized" content="320" />
        <meta name="screen-orientation" content="portrait" />
        <meta name="x5-orientation" content="portrait" />
        <meta name="full-screen" content="yes" />
        <meta name="x5-fullscreen" content="true" />
        <meta name="browsermode" content="application" />
        <meta name="x5-page-mode" content="app" />
        <meta name="msapplication-tap-highlight" content="no" />
      </Helmet>
      <Header />
      <Toast />
      {children}
      <Footer />
    </div>
  )
}
export default Layout
